// Backend.jsx

// Copyright HS Analysis GmbH, 2019
// Author: Sebastian Murgul, Viktor Eberhardt, Lukas Buess, Valentin Haas

// Framework imports
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import { io } from "socket.io-client";

// External packages
import { v4 as uuidv4 } from "uuid";

// HSA imports
import {
  AudioRoi,
  ImageRoi,
  ModificationStatus,
  RoiType,
} from "../components/RoiTypes";
import { authenticationService } from "../services";
import {
  convertDateToDayString,
  convertDateToShortIsoString,
} from "../../common/utils/Localization";
import { validateInstance } from "./Utils";
import AITrainingDataContainer from "../components/AITrainingDataContainer";
import AITrainingSettings from "../components/AITrainingSettings";
import AIModel2ProjectTypeMapping from "../../aiViewer/components/AIModel2ProjectTypeMapping";
import InstantAnalysisModule from "../components/InstantAnalysisModule";
import Structure from "../components/Structure";

const PYTHON_SERVER_PORT = 8051;
const PYTHON_SERVER_URL = `http://127.0.0.1:${PYTHON_SERVER_PORT}`;

let jobProgressConnection = new HubConnectionBuilder()
  .withUrl("/jobprogress")
  .configureLogging(LogLevel.Warning)
  .build();
jobProgressConnection.serverTimeoutInMilliseconds = 14 * 24 * 60 * 60 * 1000; // 2 weeks
jobProgressConnection.keepAliveIntervalInMilliseconds =
  14 * 24 * 60 * 60 * 1000; // 2 weeks

/**
 * injects authorization header into the fetch() function
 * @param {String} url backend endpoint
 * @param {Object} config additional stuff https://developer.mozilla.org/de/docs/Web/API/Fetch_API/Using_Fetch
 * @returns {Promise} result of fetch function
 */
function fetch_auth(url, config = {}) {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (!currentUser || !currentUser.token) {
    authenticationService.logout();
    return Promise.reject("No user token available!");
  }
  if (currentUser && currentUser.token) {
    if (!config.headers) config.headers = {};

    // place bearer token
    config.headers.Authorization = `Bearer ${currentUser.token}`;
  }
  return fetch(url, config);
}

function validateResponse(response, permissionString) {
  if (response.status === 403) {
    window.showErrorSnackbar(
      "Current user has no permission to " + permissionString + "!"
    );
    return { success: false, status: response.status };
  } else {
    return response.json();
  }
}

function validateResponseJSON(json, callback) {
  if (json.success === false && json.message !== "") {
    window.showErrorSnackbar(json.message);
  }
  callback(json);
}

function showHandledProblems(response, errorMessage) {
  if (response.permissionDenied === true) {
    window.showErrorSnackbar(errorMessage);
  }
  if (typeof response.errorMessage === "string" && window.openErrorDialog) {
    window.openErrorDialog(response.errorMessage);
  }
  return response;
}

/**
 * Generalized function for logging errors in the console.
 * Filters out resposnes that are not json.
 * @param {string} actionName Name of the action the error occured in.
 * @param {string} error The actual error message.
 */
function customErrorPrint(actionName, error) {
  if (error === "SyntaxError: Unexpected token < in JSON at position 0") {
    console.error("Backend could not be connected!");
    return;
  }

  console.error("Error occured in", actionName, error);
}

/**
 * Default handeling on a successfully parsed json response that returns an "error" key to report errors.
 * @param {JSON} res Parsed json object from response.
 * @param {Function} callback The function to call to handle a successful request.
 * @param {Function} error Optional. The function to call on any error.
 */
function handleJsonResponse(res, callback, error = () => {}) {
  if (res.error) {
    console.error(error);
    error(res.error);
  } else {
    callback(res);
  }
}

export default class Backend {
  static validateResponse(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  /**
   * UNSAFE: Bypasses authentication of .NET Core backend.
   * Returns the socket directly to the python server.
   * @returns {SocketIOClient.Socket} SocketIOClient.Socket to python server.
   */
  static getPythonServerSocket() {
    return io(PYTHON_SERVER_URL);
  }

  /**
   * Checks that the .NET backend can reach the python server via websockets.
   * For testing purposes only.
   */
  static ping() {
    fetch_auth("/api/websocket/ping")
      .then((response) => response.text())
      .then((text) => console.debug(text))
      .catch((error) => console.error(error));
  }

  // #region Login Controller
  /**
   * Get user list from login-Page
   * @param {Function} callback Success callback
   */
  static getUserList(callback) {
    fetch("/api/login/userlist")
      .then((response) => response.json())
      .then(callback);
  }
  // #endregion

  // #region Admin Controller
  static createUser(user, callback) {
    fetch_auth(`/api/admin/createuser`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then(callback);
  }

  static deleteUser(id, callback) {
    fetch_auth(`/api/admin/deleteuser`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(id),
    })
      .then((response) => response.json())
      .then(callback);
  }

  static updateUser(user, callback) {
    fetch_auth(`/api/admin/updateuser`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then(callback);
  }

  static loadUserList(callback) {
    fetch_auth(`/api/admin/userlist`)
      .then((response) => response.json())
      .then(callback);
  }

  static loadGroupList(callback) {
    fetch_auth(`/api/admin/grouplist`)
      .then((response) => response.json())
      .then(callback);
  }

  static createGroup(group, callback) {
    fetch_auth(`/api/admin/createGroup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(group),
    })
      .then((response) => response.json())
      .then(callback);
  }

  static updateGroup(group, callback) {
    fetch_auth(`/api/admin/updateGroup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(group),
    })
      .then((response) => validateResponse(response, "edit groups"))
      .then(callback);
  }

  static updateUserGroups(userId, userGroups, callback) {
    fetch_auth(`/api/admin/updateUserGroups?id=` + userId, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userGroups),
    })
      .then((response) => validateResponse(response, "edit groups"))
      .then((json) => validateResponseJSON(json, callback));
  }

  static deleteGroup(id, callback) {
    fetch_auth(`/api/admin/deleteGroup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(id),
    })
      .then((response) => validateResponse(response, "edit groups"))
      .then(callback);
  }

  /**
   * loads system configuration from appsettings.json
   * @param {Function} callback Success callback with ome data model
   */
  static async readAppSettings() {
    return fetch_auth(`/api/admin/appsettings`)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  /**
   * writes system configuration from appsettings.json
   * @param {Object} appsettings Appsettings Dictionary
   * @param {Function} callback Success callback with ome data model
   */
  static writeAppSettings(appsettings, callback) {
    fetch_auth(`/api/admin/appsettings`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(appsettings),
    })
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * checks, the changes for the case settings to show in frontend in dialog
   * @param {Object} appsettings Appsettings Dictionary
   * @param {Function} callback Success callback with ome data model
   */
  static getPossibleCaseSettingsChanges(appsettings, callback) {
    fetch_auth(`/api/admin/check_update_case_settings`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(appsettings),
    })
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * loads system configuration from appsettings.json
   * @param {Function} callback Success callback with ome data model
   */
  static readAppSettingsLicensing(callback) {
    fetch(`/api/about/appsettingslicensing`)
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * writes system configuration from appsettings.json
   * @param {Object} appsettings Appsettings Dictionary
   * @param {Function} callback Success callback with ome data model
   */
  static writeAppSettingsLicensing(appsettings, callback) {
    fetch(`/api/about/appsettingslicensing`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(appsettings),
    })
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * loads system configuration from appsettings.json
   * @param {Function} callback Success callback with ome data model
   */
  static readImporterSettings(callback) {
    fetch_auth(`/api/admin/importsettings`)
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * writes system configuration from appsettings.json
   * @param {Object} appsettings Appsettings Dictionary
   * @param {Function} callback Success callback with ome data model
   */
  static writeImporterSettings(appsettings, callback) {
    fetch_auth(`/api/admin/importsettings`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(appsettings),
    })
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * Deletes {../TEMP/files}-folder.
   * @param {Function} callback function after the deletion is done
   */
  static deleteTempFiles(callback) {
    fetch_auth(`/api/admin/delete_temp_files`)
      .then((response) => response.json())
      .then(callback);
  }
  // #endregion

  // #region User Controller
  /**
   * load list of groups of given user
   * @param {Function} callback Success callback
   */
  static getUserGroups(id, callback) {
    fetch_auth(`/api/user/getUserGroups?id=` + id)
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * load list of groups of logged in user
   * @param {Function} callback Success callback
   */
  static getCurrentUserGroups(callback) {
    fetch_auth(`/api/user/getCurrentUserGroups`)
      .then((response) => {
        if (response.status === 204 || response.status === 401) {
          authenticationService.logout();
        }
        return response.json();
      })
      .then(callback);
  }

  /**
   * Get currently logged in User
   * @param {Function} callback Success callback
   */
  static async getCurrentUser(callback) {
    fetch_auth("/api/user/current_user")
      .then((response) => {
        if (response.status === 204 || response.status === 401) {
          authenticationService.logout();
          window.showErrorSnackbar(
            "User not authenticated. Please log in again."
          );
        }
        return response.json();
      })
      .then((data) => {
        const user = data.user;
        user.group = data.group;
        if (!user.group) {
          // Force new login on missing user
          authenticationService.logout();
          window.showErrorSnackbar(
            "Could not authenticate user. Are you logged in?"
          );
          return;
        }
        callback(data.user);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * get user actions from database
   * @param {func} callback returns list of all user actions
   */
  static getUserActions(date, callback) {
    const dateFormated = date.toISOString();
    fetch_auth(`/api/user/get_user_actions?date=${dateFormated}`)
      .then((response) => {
        return response.json();
      })
      .then(callback);
  }

  /**
   *
   * @param {DateTime} date
   */
  static downloadUserActions(date) {
    const dateFormated = date.toISOString();
    const dateDay = convertDateToDayString(date);
    fetch_auth(`/api/user/download_user_actions?date=${dateFormated}`)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          window.showErrorSnackbar("Download Failed!");
          return null;
        }
      })
      .then((blob) => {
        if (blob !== null) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = dateDay + "_userActions.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        }
      });
  }
  // #endregion

  // #region About Controller
  /**
   * loads version and release notes
   * @param {Function} callback Success callback
   */
  static getAbout(callback) {
    fetch(`/api/about/about`)
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * loads version code
   * @param {Function} callback Success callback
   */
  static getVersion(callback) {
    fetch(`/api/about/version`)
      .then((response) => response.json())
      .then(callback)
      .catch((error) => customErrorPrint("getVersion", error));
  }

  /**
   * Get License Infos for LicensingPage
   * @param {function} callback Success callback
   */
  static getLicensingInfo(callback) {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("/api/about/licensingInfo", requestOptions)
      .then((response) => response.json())
      .then(callback)
      .catch((error) => console.error("Could not get licensing info:", error));
  }

  /**
   * Get Operating System
   * @param {Function} callback Success callback
   */
  static getOS(callback) {
    fetch("/api/about/os")
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        callback(result);
      })
      .catch((error) => console.error("Could not get OS information:", error));
  }
  // #endregion

  // #region Cases Controller
  /**
   * List all cases of the logged in user
   * @param {Function} callback Success callback with list as parameter
   */
  static async listCases() {
    return fetch_auth("/api/cases/list").then((response) => {
      if (response.status === 401) {
        console.log("not authenticated => logout");
        authenticationService.logout();
        return;
      }
      return response.json();
    });
  }

  /**
   * Creates a new case in database
   *
   * @param {Object} data - Object with 2 arrays: caseColumns to create 1 case and files for the case files
   * @returns {Promise} - Resolves with responseData or rejects with error
   */
  static async createCase(data) {
    try {
      const response = await fetch_auth("/api/cases/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const { data: responseData } = await response.json();
      return Promise.resolve(responseData);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  /**
   * Updates an existing case in the database based on ID
   *
   * @param {string} id - ID of the case to update
   * @param {Object} data - Object with 1 array: caseColumns to update 1 case
   * @returns {Promise} - Resolves with responseData or rejects with error
   */
  static async editCase(id, data) {
    try {
      const response = await fetch_auth(`/api/cases/edit/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      return Promise.resolve(response);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  /**
   * Delete a specific case of the logged in user
   * @param {string} caseId ID of the case to delete
   */
  static async deleteCase(caseId) {
    return fetch_auth(`/api/cases/delete/${caseId}`, { method: "DELETE" }).then(
      (response) => {
        if (response.status === 401) {
          console.log("not authenticated => logout");
          authenticationService.logout();
          return;
        }
        return response.json();
      }
    );
  }
  // #endregion

  // #region Rendering Controller
  /**
   * list all folders inside the given path
   * @param {*} path is the path to the folder the subfolders should be listed
   */
  static getDirectories(path) {
    return fetch_auth(`/api/admin/directories?path=${path}`).then(
      (response) => {
        if (response.status === 200) return response.json();
        if (response.status === 400) {
          window.showErrorSnackbar("Access to the path is denied.");
          return null;
        }
        if (response.status === 401) {
          window.showErrorSnackbar(
            "Access for authenticated admin users only."
          );
          return null;
        }
      }
    );
  }

  /**
   * Runs a instant analysis tool with real time updates using signalR
   * @param {Object} data Instant analysis configuration
   * @param {Function} callback Success callback
   * @param {Function} errorCallback Error callback
   * @param {Function} progressCallback Progress callback
   */
  static loadImage(params, callback, errorCallback, progressCallback) {
    //console.debug(JSON.stringify(data));
    const jobId = "_" + Math.random().toString(36).substr(2, 9);

    let connection = new HubConnectionBuilder()
      .withUrl("/jobprogress")
      .configureLogging(LogLevel.Warning)
      .build();
    connection.serverTimeoutInMilliseconds = 5000000; // 5000 second
    connection.on("Progress", (line) => {
      if (line) {
        progressCallback(line);
      }
    });
    connection.on("StandardOutput", (line) => {
      // output debug prints of python module
      if (line) {
        console.debug(line);
      }
    });
    connection.on("Error", (line) => {
      // output debug prints of python module
      if (line) {
        console.error(line);
      }
    });

    connection
      .start()
      .then(() => connection.invoke("AssociateJob", jobId))
      .catch((err) => console.error(err.toString()));

    fetch_auth(`/api/rendering/load_image?id=${params.id}&jobId=${jobId}`)
      .then((response) => response.json())
      .then((res) => {
        res.error ? errorCallback(res.error) : callback(res);
      })
      .catch((error) => {
        errorCallback(error);
      });
  }

  /**
   * generate path for images to render a specific region
   */
  static renderRegion(params) {
    return `/api/rendering/render_region?id=${params.id}&page=${params.page}&level=${params.lv}&x=${params.x}&y=${params.y}`;
  }

  /**
   * generate path for thumbnail image
   */
  static renderThumbnail(id) {
    return `/api/rendering/render_thumbnail?id=${id}`;
  }

  static renderSlideImg(id) {
    return `/api/rendering/render_slide_img?id=${id}`;
  }

  /**
   * get file properties
   */
  static getFileInfo(id) {
    return fetch_auth(`/api/file/get?id=${id}`, {
      method: "GET",
    }).then((response) => response.json());
  }

  /**
   * generate and load 3d objects
   * @param {Object} data Instant analysis configuration
   */
  static get3dObjects(data) {
    return `/api/rendering/get3dObjects?file_id=${data.fileId}&project_id=${data.projectId}&zRange=${data.zRange}&showPointCloud=${data.showPointCloud}`;
  }

  /**
   * check if Sony QD Job ran
   * @param {Object} data Instant analysis configuration
   */
  static get3DAllObjectsExists(data, callback) {
    fetch_auth(
      `/api/rendering/get3DAllObjectsExists?file_id=${data.fileId}&project_id=${data.projectId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.text())
      .then((data) => callback(data));
  }

  /**
   * check, if python server is running
   * @param {*} callback
   */
  static isLocalServerReady(callback) {
    fetch_auth(`/api/rendering/is_running`, {
      method: "GET",
    })
      .then((res) => res.text())
      .then((data) => callback(data.toLowerCase() === "true"))
      .catch(() => console.log("error is_running"));
  }
  // #endregion

  // #region Project Controller
  /**
   * Request a project, before actually loading it.
   * Allows to check if the project is available and to inject further actions if not.
   * @param {uuidv4} id The id of the project to request.
   * @param {Function} callback The function to call to handle a successful request.
   * @param {Function} error Optional. The function to call on any error.
   */
  static async requestProject(id, callback, error = () => {}) {
    fetch_auth(`/api/project/request?id=${id}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Error loading exported projects`);
      })
      .then((res) => handleJsonResponse(res, callback, error))
      .catch((err) => {
        console.error(err);
        error(err);
      });
  }

  /**
   * Load project meta data from backend.
   * @param {uuid} id Project id.
   * @returns {Promise} Promise with project data parsed as json.
   */
  static async loadProject(id) {
    return fetch_auth(`/api/project/get?id=${id}`).then((response) =>
      response.json()
    );
  }

  /**
   * Clean up and close database after closing a project.
   * @param {uuid} projectId Id of the closed project.
   * @param {function} callback Function to call upon success.
   * @param {function} error Function to call upon error.
   */
  static closeProject = async (projectId) => {
    return fetch_auth(`/api/project/close?projectId=${projectId}`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error)
          throw Error(`Error closing project ${projectId}:\n\n ${res.error}`);
        return res;
      })
      .catch((err) => {
        throw Error(`Error closing project ${projectId}:\n\n ${err}`);
      });
  };

  /**
   * load file annotations data from Backend
   * @param {Object} params object with project id
   * @param {Function} callback Success callback with project model object as parameter
   */
  static async loadAnnotations(params, queryOptions) {
    return fetch_auth(
      `/api/project/get_annotations?id=${params.id}&fileId=${params.fileId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(queryOptions),
      }
    ).then(async (response) => {
      const r = await response.json();
      if (response.status === 200) return r;
      if (response.status === 400) {
        window.showErrorSnackbar("Access to the path is denied.");
        return null;
      }
    });
  }

  /**
   * Merges results in multiple projects
   * @param {String[]} projectIds
   * @returns {Promise} Promise with the result of the merge parsed as JSON.
   */
  static async mergeProjectResults(projectIds) {
    return fetch_auth(`/api/project/merge_project_results`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectIds),
    }).then((response) => response.json());
  }

  /**
   * Init Annotations DB
   * @param {UUID} projectId The id of the project to load annotations from.
   * @param {RoiType} roiType Type of rois to load.
   * @param {function} callback Function to execute on success.
   * @param {function} error Function to execute on error.
   */
  static initAnnotationsFromDB(projectId, roiType, callback, error) {
    const start = performance.now();
    if (!projectId) {
      throw new Error("Missing project id, which is required.");
    }
    if (!Object.values(RoiType).includes(roiType)) {
      throw TypeError(`Invalid roiType ${roiType}.`);
    }
    // Perform the request
    fetch_auth(
      `api/project/init_annotations_db?projectId=${projectId}&roiType=${roiType}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (response.ok) {
          // TODO: Implement byte[] decoding
          return response.json();
        } else {
          return response.json();
        }
      })
      .then((res) => {
        if (res?.error) {
          error(`Error loading annotations:\n${res.error}`);
        } else {
          const end = performance.now();
          const timeInSeconds = (end - start) / 1000;
          res.time = timeInSeconds;
          callback();
        }
      })
      .catch((err) =>
        error(`Error initializing annotations database:\n${err}`)
      );
  }

  /**
   * Load annotations from backend database.
   * @param {UUID} projectId The id of the project to load annotations from.
   * @param {RoiType} roiType Type of rois to load.
   * @param {object} searchOptions Options to narrow down the rois to look for. Must consist of at minimum of fileId.
   * @param {function} callback Function to execute on success.
   * @param {function} error Function to execute on error.
   */
  static async loadAnnotationsFromDB(projectId, roiType, searchOptions) {
    try {
      if (!projectId) {
        throw new Error("Missing project id, which is required.");
      }
      if (!Object.values(RoiType).includes(roiType)) {
        throw TypeError(`Invalid roiType ${roiType}.`);
      }
      if (!searchOptions) {
        throw new Error(
          "Missing search options, must consist at minimum of fileId."
        );
      }
      if (!searchOptions.fileId) {
        throw new Error("Missing file id, which is required.");
      }
    } catch (err) {
      console.error(
        "An error occured fetching the annotaions form the database:\n",
        err
      );
      throw new Error(err);
    }

    // Perform the request
    return fetch_auth(
      `api/project/get_annotations_from_db?projectId=${projectId}&roiType=${roiType}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(searchOptions),
      }
    )
      .then((response) => {
        if (response.ok) {
          // TODO: Implement byte[] decoding
          return response.json();
        } else {
          return response.json();
        }
      })
      .then((res) => {
        if (res?.error) {
          const err = `Error loading annotations:\n${res.error}`;
          throw new Error(err);
        } else {
          let roisNotLoaded = 0;
          // Map rois to their respective types
          switch (roiType) {
            case RoiType.ImageRoi:
              res = res.map((incomingRoi) => {
                try {
                  return ImageRoi.fromObject(incomingRoi);
                } catch (err) {
                  console.error(
                    "Error converting incoming roi to ImageRoi:\n",
                    err
                  );
                  roisNotLoaded++;
                  return null;
                }
              });
              break;

            case RoiType.AudioRoi:
              res = res.map((incomingRoi) => {
                try {
                  return AudioRoi.fromObject(incomingRoi);
                } catch (err) {
                  console.error(
                    "Error converting incoming roi to AudioRoi:\n",
                    err
                  );
                  roisNotLoaded++;
                  return null;
                }
              });
              break;

            default:
              throw TypeError(`Cannot conver to invalid roiType ${roiType}.`);
          }

          if (roisNotLoaded > 0) {
            window.showErrorSnackbar(
              `Some rois (${roisNotLoaded}) could not be loaded. Please check the console for more information.`
            );
            window.showWarningSnackbar(
              `Saving the project will not save annotations (${roisNotLoaded}) that could not be loaded.`
            );
          }
          res = res.filter((roi) => roi !== null);
          return res;
        }
      });
  }

  /**
   * Get the annotation count for a project.
   * Can be narrowed down to a specific file and structure.
   * @param {uuid} projectId The id of the project to get the annotation count for.
   * @param {string} filePath Optional. The path of the file to get the annotation count for. If not provided, the count for all files is returned. Default is null.
   * @param {int} structureId Optional. The id of the structure to get the annotation count for. If not provided, the count for all structures is returned. Default is null.
   * @returns {Promise} Promise with the annotation count as an integer.
   */
  static async getAnnotationCount(
    projectId,
    filePath = null,
    structureId = null
  ) {
    let requestUrl = `/api/project/annotation_count?projectId=${projectId}`;
    if (filePath) requestUrl += `&filePath=${encodeURIComponent(filePath)}`;
    if (structureId) requestUrl += `&structureId=${structureId}`;

    return fetch_auth(requestUrl, {
      method: "GET",
    }).then((response) => {
      if (response.ok) {
        return response.text().then((text) => parseInt(text));
      }
      let errMsg = `Error loading annotation count for project ${projectId}`;
      if (filePath) errMsg += `, file ${filePath}`;
      if (structureId) errMsg += `, structure ${structureId}`;
      errMsg += `[${response.status}]`;
      if (response.statusText) errMsg += `: ${response.statusText}`;
      throw new Error(errMsg);
    });
  }

  /**
   * load file annotations data from Backend
   * @param {Object} params object with project id
   * @param {Function} callback Success callback with project model object as parameter
   */
  static loadAnnotationsObject(params, callback) {
    fetch_auth(
      `/api/project/get_annotations_object?id=${params.id}&fileId=${params.fileId}`
    )
      .then((response) => response.json())
      .then(callback);
  }

  static exportGallery(id, fileId, callback) {
    fetch_auth(`/api/project/export_gallery?id=${id}&fileId=${fileId}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * List all projects of the logged in user
   * @param {Function} callback Success callback with list as parameter
   */
  static listProjects(callback) {
    try {
      fetch_auth("/api/project/list")
        .then((response) => {
          if (response.status === 401) {
            console.log("not authenticated => logout");
            authenticationService.logout();
            return;
          }
          return response.json();
        })
        .then(callback)
        .catch((error) => {
          console.log(error);
        });
    } catch (ex) {
      console.log("error:", ex);
    }
  }

  /**
   * Get message from one project
   * @param {String} id
   * @returns Promise
   */
  static async loadProjectMessage(id) {
    return fetch_auth(`/api/project/get_message?id=${id}`).then((response) =>
      response.json()
    );
  }

  /**
   * Creates a new project in database based on the chosen template
   * @param {Object} data Project creation model
   * @returns {Promise} result of fetch function
   */
  static async createProject(data) {
    return fetch_auth(`/api/project/create`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  /**
   * Export Projects
   * @param {String[]} projectIds List of project ids to export.
   * @param {String} exportName Name of the export file.
   * @param {Function} callback Function to call on success.
   * @param {Function} err Function to call on error.
   */
  static exportProjects(projectIds, exportName, callback, err) {
    fetch_auth(`/api/project/export`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectIds),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.blob();
        } else {
          const res = await response.json();
          if (res.errorMessage) {
            throw new Error(res.errorMessage);
          }
        }
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = exportName + ".hsa";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        callback();
      })
      .catch((error) => {
        console.error(error);
        err(error);
      });
  }

  /**
   * Import exported projects.
   * @param {FormFile} file The file to import projects from.
   * @param {Function} callback The function to call to handle a successful request.
   * @param {Function} error Optional. The function to call on any error.
   */
  static importProjects(file, callback, error = () => {}) {
    const time1 = performance.now();
    var data = new FormData();
    data.append("file", file);

    fetch_auth(`/api/project/import`, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          console.info("Import took", (performance.now() - time1) / 1000, "s");
          return response.json();
        }
        throw new Error(`Error loading exported projects`);
      })
      .then((res) => handleJsonResponse(res, callback, error))
      .catch((err) => {
        console.error(err);
        error(err);
      });
  }

  /**
   * Rename Project
   * @param {String} projectId
   * @param {String} name
   * @param {Function} callback
   */
  static renameProject(projectId, name, callback) {
    let params = {
      projectId: projectId,
      updatedName: name,
    };
    fetch_auth(`/api/project/rename`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((json) => callback(json));
  }

  /**
   * Merges multiple Projects and creates new Project
   * @param {String[]} projectIds
   * @param {Function} callback
   */
  static async mergeProjects(projectIds) {
    return fetch_auth(`/api/project/merge`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectIds),
    }).then((response) => {
      return response.json();
    });
  }

  /**
   * Update Project Files
   * @param {String} projectId
   * @param {Object} model
   * @param {Function} callback
   */
  static updateProjectFiles(projectId, model, callback) {
    fetch_auth(`/api/project/update_files?id=${projectId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(model),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * Update a project with new data.
   * Possible updates include name and files.
   * @param {uuid} projectId The id of the project to update.
   * @param {object} model The model to update the project with. Uses the ProjectCreationModel format.
   * @returns {object} Basic project information.
   */
  static async updateProject(projectId, model) {
    return fetch_auth(`/api/project/update?id=${projectId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(model),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      let errMsg = `Failed to update project ${projectId} [${response.status}]`;
      if (response.statusText) {
        errMsg += `: ${response.statusText}`;
      }
      throw new Error(errMsg);
    });
  }

  /**
   * Deletes multiple Projects
   * @param {String[]} projectIds
   * @param {Function} callback
   */
  static deleteProjects(projectIds, callback) {
    fetch_auth(`/api/project/delete`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectIds),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      })
      .catch(() => {
        window.showErrorSnackbar(
          "Deleting Project failed! Are you the creator?"
        );
      });
  }

  /**
   * Checks new or edited proposed file mappings for validity in Backend.
   * @param {Tuple(Array, Array)} formData Tuple with list of all projects to import and list of all proposed file mappings.
   * @param {String} projectActionMode The action to perform on the projects. Must be a valid value of ProjectActionMode.
   * @param {Function} callback The function to call to handle a successful request. Receives {object} Response.
   * @param {Function} error Optional. The function to call on any error. Receives {string} Error.
   */
  static checkFileMappings(
    formData,
    projectActionMode,
    callback,
    error = () => {}
  ) {
    fetch_auth(`/api/project/checkFileMappings?mode=${projectActionMode}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        const projectCount = formData.projects.length;
        throw new Error(
          `Error importing ${projectCount} ${
            projectCount === 1 ? "project" : "projects"
          }`
        );
      })
      .then((res) => handleJsonResponse(res, callback, error))
      .catch((err) => {
        console.error(err);
        error(err.message);
      });
  }

  /**
   * Exports (starts download) multiple project modules as zip file
   * @param {String[]} names
   * @param {Function} callback
   */
  static exportProjectTypes(names, output_name, callback) {
    fetch_auth(`/api/project/export_project_module_types`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(names),
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = output_name + ".zip";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        callback("success");
      });
  }

  /**
   * Create Project Module Preview Image
   * @param {String} name
   * @param {String} projectJson
   * @param {Function} callback
   */
  static createProjectTypeJsonFile(name, projectJson, callback) {
    fetch_auth(`/api/project/create_project_module_type_json?name=${name}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectJson),
    })
      .then((response) => response.json())
      .then((res) =>
        showHandledProblems(res, "No permission to create project modules!")
      )
      .then((res) => callback(res));
  }

  /**
   * Create Project Module Preview Image
   * @param {String} name
   * @param {FormFile} img
   * @param {Function} callback
   */
  static createProjectTypeImage(name, img, callback) {
    console.log("createProjectTypeImage()");
    let data = new FormData();
    data.append("file", img);

    fetch_auth(`/api/project/create_project_module_type_image?name=${name}`, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((res) =>
        showHandledProblems(res, "No permission to create project modules!")
      )
      .then((res) => callback(res));
  }

  /**
   * Save screenshot in slides sub folder
   * @param {String} name
   * @param {FormFile} img
   * @param {Function} callback
   */
  static saveScreenshot(folderPath, fileName, img, callback) {
    let data = new FormData();
    data.append("file", img);
    fetch_auth(
      `/api/project/save_screenshot?fileName=${fileName}&folderPath=${folderPath}`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => response.json())
      .then((res) => callback(res));
  }

  /**
   * Delete Project Type Preview Image and json file
   * @param {String} name project type name
   * @returns {Promise} result of fetch function
   */
  static async deleteProjectType(name) {
    return fetch_auth(`/api/project/delete_project_module_type?name=${name}`, {
      method: "POST",
    }).then((response) => response.json());
  }

  /**
   * Imports Exported Project Types
   * @param {FormFile} fileMappings
   * @param {Function} callback
   * @param {Function} errorCallback
   */
  static importProjectTypes(fileMappings, callback, errorCallback) {
    var data = new FormData();
    data.append("file", fileMappings);
    fetch_auth(`/api/project/import_project_types`, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((res) => (res.error ? errorCallback(res.error) : callback(res)))
      .catch((error) => errorCallback(error));
  }

  /**
   * edit project type
   * @param {Object} projectType
   * returns {Promise}
   */
  static async editProjectType(projectType) {
    return fetch_auth(`/api/admin/edit_project_type`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectType),
    }).then((response) => response.json());
  }

  /**
   * Deletes Gallery Export of Projects
   * @param {String[]} projectNames
   * @param {Function} callback
   */
  static deleteGalleryExport(projectNames, callback) {
    fetch_auth(`/api/project/deleteGallery`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectNames),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * Duplicate multiple Projects
   * @param {String[]} projectIds
   * @param {Function} callback
   */
  static duplicateProjects(projectIds, callback) {
    fetch_auth(`/api/project/duplicate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectIds),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * Sets multiple Projects to pending
   * @param {String[]} projectIds
   * @param {Function} callback
   */
  static setProjectsPending(projectIds, evalOnly = false, callback) {
    fetch_auth(`/api/project/pending`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ projectIds: projectIds, evalOnly: evalOnly }),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * Sets multiple Projects to timed
   * @param {String[]} projectIds
   * @param {Function} callback
   */
  static setProjectsTimed(projectIds, callback) {
    fetch_auth(`/api/project/timed`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectIds),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * saves project data into a json file
   * @param {Object} data project model
   * @returns {Promise} Feedback from the backend when saving the project.
   */
  static saveProject(data) {
    return fetch_auth(`/api/project/saveProject`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  /**
   * saves annotations into json files
   * @param {String} projectId
   * @param {Object} data project model
   * @param {Function} callback
   */
  static saveAnnotations(projectId, fileId, data, callback) {
    // https://stackoverflow.com/questions/50918007/accepting-byte-in-a-net-core-webapi-controller
    // data = new Blob([data.buffer]);
    fetch_auth(
      `/api/project/saveAnnotations?projectId=${projectId}&fileId=${fileId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/octet-stream" },
        body: data,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (callback) {
          callback(data);
        }
      })
      .catch((error) => console.log("error", error));
  }

  /**
   * sends image to ftp server to simulate stream
   *
   */
  static streamRenderer(data, remoteUuid, callback) {
    fetch_auth(`/api/project/streamRenderer?remoteUuid=${remoteUuid}`, {
      method: "POST",
      headers: { "Content-Type": "application/octet-stream" },
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error) => console.log("error", error));
  }

  /**
   * Load the structures of a project from backend.
   * @param {uuid} projectId Id of the project to load the structures for.
   * @returns {Promise} List of Strucutre objects.
   */
  static async loadStructures(projectId) {
    return fetch_auth(`/api/project/load_structures?projectId=${projectId}`, {
      method: "GET",
    })
      .then((res) => {
        return res.ok
          ? res.json()
          : { error: `Failed to load structures for project ${projectId}` };
      })
      .then((res) => {
        if (res.error) {
          throw Error(res.error);
        } else {
          // Cast to structure class
          return res.map((s) => Structure.fromObject(s));
        }
      });
  }

  /**
   * Save structures of a project to its database.
   * @param {uuid} projectId Id of project to save annotations for.
   * @param {array} structures Structures to save as list.
   * @returns {Promise} Promise object representing the result of the request.
   */
  static async saveStructures(projectId, structures) {
    const tmpStructures = structures.map((s) => {
      const tmpstruct = Structure.copy(s);
      tmpstruct.toolSettings = JSON.stringify(tmpstruct.toolSettings);
      return tmpstruct;
    });
    return fetch_auth(`/api/project/save_structures?projectId=${projectId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpStructures),
    })
      .then((res) => {
        if (res.ok) return res.json();
        let errMsg = `Failed to save structures to project database [${res.status}]`;
        if (res.statusText !== "") errMsg += `: ${res.statusText}`;
        throw new Error(errMsg);
      })
      .then((data) => {
        if (data?.error) throw new Error(data.error);
        return data;
      });
  }

  /**
   * Deletes all annotations for a file (or for a specific structure in a file)
   * from the project annotation database.
   * WARNING: Only use after ensuring that annotations have been saved (in case of network errors).
   * @param {UUID} projectId Id of project to delete annotations from.
   * @param {UUID} fileId Id of file to delete annotations from.
   * @param {int} structureId Optional. Id of structure to delete annotations from. Default is null.
   * @param {boolean} force Optional. If true, will not ask user for explicit confirmation. Default is false.
   * @returns {Promise} Promise object representing the result of the request.
   */
  static async clearAnnotationsFromDb(
    projectId,
    fileId,
    structureId = null,
    force = false
  ) {
    // Confirm deletion by user if not forced.
    if (!force) {
      const confirmed = await window.openConfirmationDialog(
        "Delete all annotations?",
        structureId
          ? `Are you sure you want to delete all annotations for this structure (${structureId}) in this file?`
          : "Are you sure you want to delete all annotations for this file?"
      );
      if (!confirmed) return;
    }

    let url = `/api/project/clear_annotations_from_db?projectId=${projectId}&fileId=${fileId}`;
    if (structureId) url += `&structureId=${structureId}`;

    const response = await fetch_auth(url, { method: "GET" });
    return response.json();
  }

  /**
   * Save rois of a project to the projects annotation database.
   * Will delete all annotations in db and replace them with the updated ones.
   * @param {UUID} projectId Id of project to save annotations for.
   * @param {RoiType} roiType Type of rois to be saved.
   * @param {array} rois List of all rois to be saved.
   * @param {function} callback Function to execute on success.
   * @param {function} error Function to execute on error.
   */
  static saveLegacyAnnotationsToDb(projectId, roiType, rois, callback, error) {
    // Check for missing input
    if (!projectId || !Object.values(RoiType).includes(roiType) || !rois)
      return;

    // Reduce rois to those modified
    rois = rois.filter(
      (roi) => roi.modificationStatus !== ModificationStatus.Saved
    );

    // No rois to save
    if (rois.length === 0) {
      callback({
        success: "All rois already saved",
        roiUpdate: {
          reassignedIds: [],
          failedToAdd: [],
          failedToDelete: [],
        },
      });
      return;
    }

    // Identify new rois
    const roisToAdd = rois.filter(
      (roi) => roi.modificationStatus === ModificationStatus.Added
    );
    roisToAdd
      .filter((roi) => roi instanceof ImageRoi)
      .forEach((roi) => {
        roi.coordinates =
          typeof roi.coordinates === "string"
            ? roi.coordinates
            : JSON.stringify(roi.coordinates);
      });

    // Identify deleted rois, keep only ids
    const roiIdsToDelete = rois
      .filter((roi) => roi.modificationStatus === ModificationStatus.Deleted)
      .map((roi) => roi.id);

    const data = new Blob([JSON.stringify({ roisToAdd, roiIdsToDelete })]);

    fetch_auth(
      `/api/project/saveLegacyAnnotations?projectId=${projectId}&roiType=${roiType}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/octet-stream" },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        data.error || data.warning ? error(data) : callback(data);
      })
      .catch((err) => error({ error: err.toString() }));
  }

  /**
   * Save rois of a project to the projects annotation database.
   * @param {UUID} projectId Id of project to save annotations for.
   * @param {RoiType} roiType Type of rois to be saved.
   * @param {array} rois List of all rois to be saved.
   * @param {function} callback Function to execute on success.
   * @param {function} error Function to execute on error.
   */
  static saveAnnotationsToDb(projectId, roiType, rois, callback, error) {
    // Check for missing input
    if (!projectId || !Object.values(RoiType).includes(roiType) || !rois)
      return;

    // Reduce rois to those modified
    rois = rois.filter(
      (roi) => roi.modificationStatus !== ModificationStatus.Saved
    );

    // No rois to save
    if (rois.length === 0) {
      callback({
        success: "All rois already saved",
        roiUpdate: {
          reassignedIds: [],
          failedToAdd: [],
          failedToDelete: [],
        },
      });
      return;
    }

    // Identify new rois
    const roisToAdd = rois.filter(
      (roi) => roi.modificationStatus === ModificationStatus.Added
    );

    roisToAdd
      .filter((roi) => roi instanceof ImageRoi)
      .forEach((roi) => {
        roi.coordinates =
          typeof roi.coordinates === "string"
            ? roi.coordinates
            : JSON.stringify(roi.coordinates);
      });

    // Identify deleted rois, keep only ids
    const roiIdsToDelete = rois
      .filter((roi) => roi.modificationStatus === ModificationStatus.Deleted)
      .map((roi) => roi.id);

    const data = new Blob([JSON.stringify({ roisToAdd, roiIdsToDelete })]);

    fetch_auth(
      `/api/project/save_annotations_to_db?projectId=${projectId}&roiType=${roiType}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/octet-stream" },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        data.error || data.warning ? error(data) : callback(data);
      })
      .catch((err) => error({ error: err.toString() }));
  }

  /**
   * saves project data into a json file
   * @param {Object} data project model
   * @param {Function} callback
   */
  static saveGallery() {}

  /**
   * load project type viewer configuration
   * @param {String} projectType name of project type
   * @param {Function} callback
   */
  static loadViewerConfig(projectType, callback) {
    fetch_auth(
      `/api/project/load_viewer_config?name=${encodeURIComponent(projectType)}`
    )
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * load all available project types
   * only load once, otherwise wait for a variable to be filled before returning result
   * @param {Boolean} forceReload force reload/fetch of project types
   * @returns {Promise} list of all project types
   */
  static loadAvailableProjectTypes(forceReload) {
    return new Promise((resolve, reject) => {
      if (forceReload || typeof window.availabeProjectTypes === "undefined") {
        window.availabeProjectTypes = "blocked";

        fetch_auth("/api/project/get_project_types")
          .then((response) => response.json())
          .then((modules) => {
            modules.forEach((module) => {
              module.structures.forEach((structure) => {
                structure.tools = structure.tools.map((tool) =>
                  InstantAnalysisModule.fromObject(tool)
                );
              });
            });

            window.availabeProjectTypes = modules;
            resolve(modules);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      } else {
        const waitForElement = () => {
          if (window.availabeProjectTypes !== "blocked") {
            resolve(window.availabeProjectTypes);
          } else {
            setTimeout(waitForElement, 200);
          }
        };
        waitForElement();
      }
    });
  }

  /**
   * load all available project types, but only return reduced information, to be faster
   * also save and load from local storage
   * @param {Function} callback contains list of all project types
   */
  static loadReducedAvailableProjectTypes(callback) {
    //let t0 = performance.now();
    Backend.getVersion((data) => {
      const ptKey = "projectTypes_" + data.version;
      let projectTypes = JSON.parse(localStorage.getItem(ptKey));
      if (projectTypes === null) {
        Backend.loadAvailableProjectTypes(false).then((projectTypesList) => {
          projectTypes = projectTypesList.map((item) => {
            return {
              annotations: item.annotations,
              description: item.description,
              isUserModule: item.isUserModule,
              name: item.name,
              order: item.order,
              label: item.label,
            };
          });
          localStorage.setItem(ptKey, JSON.stringify(projectTypes));
          //console.log("loadRAPTs time:", performance.now() - t0);
          callback(projectTypes);
        });
      } else {
        //console.log("loadRAPTs time:", performance.now() - t0);
        callback(projectTypes);
      }
    });
  }

  /**
   * load all available project types
   * @param {String} dir directory to list files within
   * @param {Function} callback contains list of all project types
   */
  static walkCaseFiles(path, isRelative, callback) {
    fetch_auth(
      `/api/project/walk_caseFiles?path=${encodeURIComponent(
        path
      )}&isRelative=${isRelative}`
    )
      .then((response) => response.json())
      .then(callback)
      .catch((error) => {
        console.log("error", error);
        window.showErrorSnackbar("Error loading directory!");
      });
  }

  /**
   * load all available project types
   * @param {String} dir directory to list files within
   * @param {Function} callback contains list of all project types
   */
  static walkProjectDirFiles(dir, callback) {
    fetch_auth(
      `/api/project/walk_project_dir_files?path=${encodeURIComponent(dir)}`
    )
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * Cancel Job
   * @param {String} id
   */
  static cancelJob(id) {
    fetch_auth(`/api/project/cancel`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(id),
    }).then((response) => response.json());
  }

  static async editCaseProjects(caseId, projectId, task) {
    try {
      return fetch_auth("/api/cases/editCaseProjects", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ caseId, projectId, task }),
      })
        .then((response) => response.json())
        .then((result) => {
          return result;
        });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Check if String has JSON-Format
   * @param {String} str
   */
  static isJsonString(str) {
    try {
      // for (var i = 0; i < str.length; i++) {

      // }
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Get the loaction of thumbnails for a image based on its path.
   * @param {string} image_path Path to the image being loaded.
   * @returns {string} Api link to image thumbnail.
   */
  static imageThumbnail(image_path) {
    return `/api/project/image_thumbnail?path=${image_path}`;
  }

  /**
   * Get the loaction of thumbnails for a image based on its path.
   * @param {string} image_path Path to the image being loaded.
   * @returns {object} object containing uuid.
   */
  static async getFileUuid(image_path) {
    let obj = {};
    await fetch_auth(
      `/api/project/file_uuid?path=${encodeURIComponent(image_path)}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        obj = data;
      });

    return obj;
  }

  /**
   * Trigger download of file.
   * @param {String} id Project UUID
   * @param {String} exportName Name of the file to be exported. Defaults to "report".
   */
  static downloadReport(id, exportName = `report`) {
    // Add timestamp to exported file.
    exportName += `_${convertDateToShortIsoString(Date.now())}`;
    fetch_auth(`/api/project/report?id=${id}`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = exportName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }

  /**
   * Retrieve report for a specific project from backend.
   * @param {UUID} id Project id for which to get the report.
   * @param {function} callback Function processing the loaded report. Recieves a UInt8Array.
   * @param {function} error Fallback function on error.
   */
  static loadReport(id, callback, error) {
    const currentUser = authenticationService.currentUserValue;
    let url = `${window.location.origin}/api/project/report?id=${id}`;

    // Set up XHR Request
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.setRequestHeader("Authorization", `Bearer ${currentUser.token}`);
    req.responseType = "arraybuffer";
    req.onload = () => {
      let data = new Uint8Array(req.response);
      callback(data);
    };
    req.onerror = (err) => {
      error(err);
    };
    req.send();
  }

  /**
   * generate image source to report chart
   * @param {String} id Project ID
   */
  static renderReportChart(path) {
    return `/api/project/report_chart?path=${path}`;
  }

  /**
   * Runs a instant analysis tool with real time updates using signalR
   * @param {Object} data Instant analysis configuration
   * @param {Function} callback Success callback
   * @param {Function} error Error callback
   */
  static aiTrainingSignalR(
    data,
    progressCallback,
    successCallback,
    errorCallback
  ) {
    let modelType = "none";
    let isVdlModel = false;
    if (data.parameters && data.parameters.modelType) {
      modelType = data.parameters.modelType;
      if (modelType == "instance segmentation") {
        // check if vdl model
        if (data.parameters.advancedSettings.comDLArchitecture === null) {
          isVdlModel = true;
        } else if (
          data.parameters.advancedSettings.comDLArchitecture &&
          data.parameters.advancedSettings.comDLArchitecture.label ==
            "Mask R-CNN VDL"
        ) {
          isVdlModel = true;
        }
      }
    }

    jobProgressConnection.off("Progress");
    jobProgressConnection.on("Progress", (line) => {
      if (line) {
        progressCallback(line);
      }
    });
    jobProgressConnection.off("StandardOutput");
    jobProgressConnection.on("StandardOutput", (line) => {
      // output debug prints of python module
      if (line) {
        console.debug(line);
        if (typeof window.trainingProgress !== "function") return;
        window.trainingProgress(line);

        if (typeof window.updateTrainingData !== "function") return;
        window.updateTrainingData(line, modelType, isVdlModel);
      }
    });
    jobProgressConnection.off("StandardError");
    jobProgressConnection.on("StandardError", (line) => {
      // output debug prints of python module
      if (line) {
        window.trainingProgress(line);
        if (typeof window.updateTrainingData === "function") {
          window.updateTrainingData(line, modelType, isVdlModel);
        }
      }
    });
    jobProgressConnection.off("Result");
    jobProgressConnection.on("Result", (json) => {
      if (json.error) {
        console.log("training callback error:", json);
        // there was an exception in python module
        errorCallback(json.error);
      } else {
        // success
        successCallback(json.project);
      }
    });

    fetch_auth(`/api/ai/train`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((myJson) => {
        if (jobProgressConnection.state === 0) {
          jobProgressConnection
            .start()
            .then(() =>
              jobProgressConnection.invoke("AssociateJob", myJson.jobId)
            )
            .catch((err) => console.error(err.toString()));
        } else {
          jobProgressConnection
            .invoke("AssociateJob", myJson.jobId)
            .catch((err) => console.error(err.toString()));
        }
      });
  }

  /**
   * Runs a Grid annotation tool with real time updates
   * @param {Object} data export Data
   * @param {Function} callback Success callback
   * @param {Function} error Error callback
   */
  static tileExport(data) {
    let connection = new HubConnectionBuilder()
      .withUrl("/jobprogress")
      .configureLogging(LogLevel.Warning)
      .build();
    connection.serverTimeoutInMilliseconds = 5000000; // 5000 second
    connection.on("Progress", (line) => {
      if (line) {
        console.log("line", line);
      }
    });
    connection.on("StandardOutput", (line) => {
      if (line) {
        window.tileExportProgress(line);
      }
    });
    connection.on("Result", (json) => {
      if (json.error) {
        console.log("json error");
      }
    });

    fetch_auth(`/api/project/tileexport`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((myJson) => {
        connection
          .start()
          .then(() => connection.invoke("AssociateJob", myJson.jobId))
          .catch((err) => {
            console.error(err.toString());
            console.log("error catch");
          });
      });
  }
  // #endregion

  // #region Ai Controller
  /**
   * Get all model inforamtion from both local as well as optionally from online sources.
   * The respose will be parsed and the models passed to callback as json.
   * @param {String} model_path "hsa_models".
   * @param {Bool} online Should models be donwloaded from the HSA online server. Defaults to false.
   * @returns {Promise} List of all models.
   */
  static async getModelMetadata(model_path, online = false) {
    return fetch_auth(
      `/api/ai/get_model_metadata?model_path=${model_path}&online=${online}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          console.log(`Received status code: ${response.status}`);
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.json();
      })
      .then((result) => {
        if (result.error) {
          throw new Error(result.details || result.error);
        }

        // In case of models that could not be loaded
        if (result.unlistedModels !== "[]") {
          const unlistedModels = JSON.parse(result.unlistedModels);
          const modelErrs = unlistedModels.map(
            (model) => `\n${model.name} - ${model.version}: \t${model.shortErr}`
          );
          throw Error(
            `Not all models could be loaded, missing models are:${modelErrs}\n\nFor further information see console.`
          );
        }

        const models = {
          models: JSON.parse(result.models),
          newModels: JSON.parse(result.new_model).map((m) =>
            AITrainingDataContainer.fromObject(m)
          ),
        };
        return models;
      });
  }

  /**
   * Read AI Model to Project Module mapping json
   * @returns {Promise} list of AIModel2ProjectTypeMapping objects
   */
  static async readAIModel2ProjectModuleMapping() {
    return fetch_auth("/api/ai/read_model_2_project_type_mapping", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json.map((i) => AIModel2ProjectTypeMapping.fromObject(i));
      });
  }

  /**
   * Read Module mapping for one AI Model
   */
  static async readProjectTypeMappingForAIModel(modelName) {
    return fetch_auth(
      `/api/ai/read_project_type_mapping_for_model?modelName=${modelName}`,
      {
        method: "GET",
      }
    ).then((response) => {
      return response.json();
    });
  }

  /**
   *
   * @param {string} modelName name of the model
   * @param {array[object]} projectTypeMapping list of module mappings
   * @returns
   */
  static writeProjectTypeMappingForAIModel(modelName, projectTypeMapping) {
    const jsonString = JSON.stringify(projectTypeMapping);
    const fetch_url = `/api/ai/write_project_type_mapping_for_model?modelName=${modelName}`;
    return fetch_auth(fetch_url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: jsonString,
    });
  }

  /**
   * Pre-load python modules in backend to save loading times when actually needed.
   */
  static initPythonModules() {
    fetch_auth(`/api/ai/init_python_modules`, {
      method: "POST",
    });
  }

  /**
   * Export an AI model to a .modelhsa file and triggers its download.
   * @param {Object} aiModel The model to export.
   * @param {Function} callback What to
   */
  static exportAIModel(aiModel, callback, errorCallback = null) {
    fetch_auth(`/api/ai/export_ai_model`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(aiModel),
    })
      .then((response) => {
        if (response.ok) {
          callback(response);
          return response.blob();
        } else {
          callback(response);
          window.showErrorSnackbar("No permission to export models!");
          return null;
        }
      })
      .then((blob) => {
        if (blob !== null) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = JSON.parse(aiModel)["Name"] + ".modelhsa";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        }
      })
      .catch((error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }

  /**
   * Export an AI model dataset to a .hsa file and triggers its download.
   * @param {Object} aiModel The model to export.
   * @param {Function} callback What to
   */
  static exportAIModelDataset(aiModel, callback) {
    fetch_auth(`/api/ai/export_ai_model_dataset`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(aiModel),
    })
      .then((response) => {
        if (response.ok) {
          callback(response);
          return response.blob();
        } else {
          callback(response);
          window.showErrorSnackbar("No permission to export models!");
          return null;
        }
      })
      .then((blob) => {
        if (blob !== null) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = JSON.parse(aiModel)["Name"] + "_dataset_project.hsa";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        }
      });
  }

  /**
   * Deletes selected Custom AI Model
   * @param {*} selectedAIModel
   * @param {*} callback
   */
  static deleteAIModel(aiModel, callback) {
    fetch_auth(`/api/ai/delete_models`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(aiModel),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * Imports Exported AI Models
   * @param {FormFile} file
   * @param {Function} callback
   */
  static importAIModels(file, callback) {
    var data = new FormData();
    data.append("file", file);

    fetch_auth(`/api/ai/import_ai_model`, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((res) =>
        showHandledProblems(res, "No permission to import models!")
      )
      .then((res) => callback(res));
  }

  /**
   * Get Cuda status from backend.
   * @param {Function} callback Success callback with cuda status as parameter.
   * @param {Function} error Error callback.
   */
  static getCudaStatus(callback, error) {
    fetch_auth(`/api/ai/cuda_status`)
      .then((response) => response.json())
      .then((res) => {
        if (res.error !== "") {
          error("Could not get Cuda status: ", res.error);
        } else if (!res.payload) {
          error("Could not get Cuda status, missing payload.");
        }
        callback(res.payload);
      })
      .catch((err) => {
        error("Error getting Cuda status: ", err);
      });
  }

  /**
   * Runs a instant analysis tool with real time updates using signalR.
   * New version of the previous function, Backend.aiTrainingSignalR.
   * @param {AITrainingSettings} data Contains the projectId as well as the parameters for the training.
   */
  static async trainModel(data) {
    validateInstance("data", data, AITrainingSettings);

    // Structure Parameters and Toolsettings must be stringified
    data.structures.forEach((s) => {
      s.toolSettings = JSON.stringify(s.toolSettings);
      s.optionalParams = JSON.stringify(s.optionalParams);
    });

    window.showSuccessSnackbar("Sent training parameters.");

    return fetch_auth(`/api/ai/train_model`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  /**
   * Get the tensorboard scalars.
   * @param {string} modelName Id of the closed project.
   * @param {function} callback Function to call upon success.
   * @param {function} error Function to call upon error.
   */
  static loadTensorboardScalarData = (
    modelName = "",
    callback,
    error = () => {}
  ) => {
    // TBD send API Call to ASP.NET
    fetch_auth(`/api/ai/scalar_data?modelName=${modelName}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (!res.ok) {
          return {};
        }
      })
      .then((res) =>
        res.error
          ? error(`Error closing project ${modelName}:\n\n ${res.error}`)
          : callback(res)
      )
      .catch((err) =>
        error(`Error parsing response, actions failed:\n\n ${err}`)
      );
  };

  /**
   * Get the current training epoch from tensorboard.
   * @param {string} modelName Name of the model.
   * @param {function} callback Function to call upon success.
   * @param {function} error Function to call upon error.
   */
  static getTrainingEpochProgress = (
    modelName = "",
    callback,
    error = () => {}
  ) => {
    // TBD send API Call to ASP.NET
    fetch_auth(`/api/ai/training_epochs_progress?modelName=${modelName}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (!res.ok) {
          return {};
        }
      })
      .then((res) =>
        res.error
          ? error(
              `Error reading epochs progress ${modelName}:\n\n ${res.error}`
            )
          : callback(res)
      )
      .catch((err) =>
        error(`Error parsing response, actions failed:\n\n ${err}`)
      );
  };

  /**
   * Runs a instant analysis tool with real time updates using signalR
   * @param {Function} callback Success callback
   */
  static stopAITraining(callback) {
    fetch_auth(`/api/ai/stop_train`)
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * Opens a dialog where you can select an AI model for the selected structure
   * @param {Function} callback
   */
  static getAIModelDialogData(callback) {
    fetch_auth(`/api/ai/custom_models`)
      .then((response) => response.json())
      .then(callback);
  }

  /**
   * Copies the selected AI models to {../TEMP/custom_models/verified}-folder. This folder is accessable from the AICockpit.
   * @param {string} verifiedAIModel selected ai model which gets copied to verified models folder
   * @param {Function} callback
   */
  static addCustomAIModels(verifiedAIModel, callback) {
    fetch_auth(`/api/ai/add_custom_models?verifiedAIModel=${verifiedAIModel}`)
      .then((response) => response.json())
      .then((res) => callback(res));
  }

  /**
   * Deletes selected Custom AI Model
   * @param {*} selectedAIModel
   * @param {*} callback
   */
  static deleteCustomAIModels(selectedAIModel, callback) {
    fetch_auth(`/api/ai/delete_custom_models`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedAIModel),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * gets the sourcepath of selected verified model
   * @param {*} selectedAIModel
   * @param {*} callback
   */
  static getSelectedModelSourcepath(selectedAIModel, callback) {
    fetch_auth(`/api/ai/get_model_sourcepath`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedAIModel),
    })
      .then((response) => response.json())
      .then((json) => {
        // success
        callback(json);
      });
  }

  /**
   * sends imageData and points to backend to get a poygon using SAM
   *
   * @param {object} samData - p1, p2 and points for foreground and background with form {x: x, y: y, isForeground: boolean}
   **/
  static async useSAM(data) {
    return fetch_auth("/api/ai/use_sam", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((error) => console.error("caught error:", error));
  }
  // #endregion

  // #region File Controller
  /**
   * load all available project types
   * @param {String} dir directory to list files within
   * @param {Boolean} isRelative if dir is relative to HSA Slides directory.
   * @param {Function} callback contains list of all project types
   */
  static async walkDir(dir, isRelative) {
    return fetch_auth(
      `/api/file/walk_dir?path=${encodeURIComponent(
        dir
      )}&isRelative=${isRelative}`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.log("error", error);
        window.showErrorSnackbar("Error loading directory!");
      });
  }

  /**
   * load a list of all jobs
   * @returns {Promise} list of all jobs
   */
  static async getModuleDirNames() {
    return fetch_auth("/api/file/get_module_dir_names")
      .then((response) => response.json())
      .catch((error) => {
        console.log("error", error);
        window.showErrorSnackbar("Error loading directory names!");
      });
  }
  // #endregion

  // #region Analysis Controller
  /**
   * Runs a instant analysis tool with real time updates using signalR
   * @param {Object} data Instant analysis configuration
   * @param {Function} callback Success callback
   * @param {Function} error Error callback
   */
  static instantAnalysisSignalR(data, spinloader, callback, error) {
    //console.log("data:", data);

    // connection.on("Progress", (line) => {
    //   // output debug prints of python module
    //   //console.debug(line);
    // });
    jobProgressConnection.off("StandardOutput");
    jobProgressConnection.on("StandardOutput", (line) => {
      // output debug prints of python module

      // output warning
      if (line) {
        if (this.isJsonString(line)) {
          const jsonLine = JSON.parse(line);
          for (var k in jsonLine) {
            switch (k) {
              case "warning":
                window.showWarningSnackbar(jsonLine["warning"]);
                break;
              default:
                break;
            }
          }
        } else {
          window.trainingProgress(line);
        }
        if (line.includes("[DownloadProgress]")) {
          let progress = line.replace("[DownloadProgress]", "");
          spinloader.showWithProgress({
            message: "Download Model",
            progress: parseInt(progress, 10),
          });
        } else if (line.includes("[IAMProgress]")) {
          let progress = line.replace("[IAMProgress]", "");
          spinloader.showWithProgress({
            message: "Progress",
            progress: parseInt(progress, 10),
          });
        } else if (!line.includes("[MESSAGE]")) {
          console.debug(line);
          spinloader.show();
        } else if (line.includes("iam error:")) {
          console.error(line);
          error(line);
        }
      }
    });
    jobProgressConnection.off("Result");
    jobProgressConnection.on("Result", (json) => {
      if (Object.keys(json).includes("error") && json.error !== false) {
        console.error("Error running iam:", json.error);
        // there was an exception in python module
        error(`Error running iam: ${json.error}`);
      } else {
        // success
        json.project.files.forEach((file) => {
          file.annotations.forEach((annotation) => {
            annotation.geoJSON = JSON.parse(annotation.serializedGeoJSON);
            annotation.serializedGeoJSON = null;
          });
        });
        callback(json.project);
      }
    });

    fetch_auth(`/api/analysis/iam`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((myJson) => {
        if (jobProgressConnection.state === 0) {
          // 0 = disconnected
          jobProgressConnection
            .start()
            .then(() =>
              jobProgressConnection.invoke("AssociateJob", myJson.jobId)
            )
            .catch((err) => console.error(err.toString()));
        } else {
          jobProgressConnection
            .invoke("AssociateJob", myJson.jobId)
            .catch((err) => console.error(err.toString()));
        }
      });
  }

  /**
   * Runs a instant analysis tool
   * @param {Object} data Instant analysis configuration
   * @param {Function} callback Success callback
   * @param {Function} error Error callback
   */
  static instantAnalysis(data, callback, error) {
    fetch_auth(`/api/analysis/iam`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json.stdout);
        if (json.error) {
          error(json.error);
        } else {
          // success
          callback(json.project);
        }
      });
  }

  /**
   * Run a IAM with the given configuration.
   * @param {IAMConfig} iamConfig Configuration for the IAM.
   * @returns {Promise} Promise containing the jobId.
   */
  static async runIAM(iamConfig) {
    fetch_auth(`/api/analysis/run_iam`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(iamConfig),
    }).then((res) => {
      if (res.ok) return res.json().then((json) => json.jobId);

      let errMsg = `Error running IAM [${res.status}]`;
      if (res.statusText) errMsg += `: ${res.statusText}`;
      console.error(errMsg);
      throw new Error(errMsg);
    });
  }

  /**
   * Runs a instant analysis tool with real time updates using signalR
   * @param {Object} data Instant analysis configuration
   * @param {Function} callback Success callback
   * @param {Function} error Error callback
   */
  static activeLearningSignalR(data, callback, error) {
    //console.debug(JSON.stringify(data));

    let connection = new HubConnectionBuilder()
      .withUrl("/jobprogress")
      .configureLogging(LogLevel.Warning)
      .build();
    connection.serverTimeoutInMilliseconds = 5000000; // 5000 second
    // connection.on("Progress", (line) => {
    //   // output debug prints of python module
    //   //console.debug(line);
    // });
    connection.on("StandardOutput", (line) => {
      // output debug prints and warnings of python module
      if (line) {
        if (this.isJsonString(line)) {
          const jsonLine = JSON.parse(line);
          for (var k in jsonLine) {
            switch (k) {
              case "warning":
                window.showWarningSnackbar(jsonLine["warning"]);
                break;
              default:
                break;
            }
          }
        } else {
          window.galleryTrainingProgress(line);
        }
        console.debug(line);
      }
    });
    connection.on("Result", (json) => {
      if (json.error) {
        // there was an exception in python module
        error(json.error);
      } else {
        // success
        callback(json.alModel);
      }
    });

    fetch_auth(`/api/analysis/alm`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((myJson) => {
        connection
          .start()
          .then(() => connection.invoke("AssociateJob", myJson.jobId))
          .catch((err) => console.error(err.toString()));
      });
  }

  /**
   * Receives instructions on how to perform PCA in json format
   * Sends results in json format. Gives real time updates.
   * @param {JSON} graphRequestSettings Specification of the request
   * @param {Function} callback Success callback on json request
   * @param {Function} error Error callback on json request
   * @param {Function} progressCallback Webhook callback for progress
   */
  static requestSpectraData(
    graphRequestSettings,
    callback,
    error,
    progressCallback
  ) {
    let connection = new HubConnectionBuilder()
      .withUrl("/jobprogress")
      .configureLogging(LogLevel.Warning)
      .build();
    connection.serverTimeoutInMilliseconds = 5000000; // 5000 second
    connection.on("StandardOutput", (line) => {
      // output debug prints of python module
      if (line) {
        // NOTE: [MESSAGE] is kept as compatibility with independent import functions
        // used for Image import, background AI processes, etc.
        if (line.startsWith("[MESSAGE]")) {
          // Remove [MESSAGE] and extract JSON payload
          progressCallback(JSON.parse(line.slice(9)));
        } else {
          console.debug(line);
        }
      }
    });

    // Add GUID for WebHook
    graphRequestSettings.jobId = uuidv4();
    connection
      .start()
      .then(() => connection.invoke("AssociateJob", graphRequestSettings.jobId))
      .catch((err) => {
        console.log("error catch");
        console.error(err.toString());
      });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(graphRequestSettings),
    };

    fetch_auth("/api/analysis/pcaupdate", requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.result.error) {
          // there was an exception in python module
          error(json.result.error);
        } else {
          // success
          callback(json.result);
        }
      });
  }

  /**
   * Request all IAMs from the backend, with their default settings.
   * If a specific IAM is requested, only that IAM with its default settings will be returned.
   * @param {string} iamName Optional. The name of the IAM to get the data for. If not provided, all IAMs will be returned. Defaults to null.
   * @returns {Promise} Promise containing the IAMs.
   */
  static async getIams(iamName = null) {
    const requestString = iamName
      ? `/api/analysis/iams?name=${iamName}`
      : `/api/analysis/iams`;
    return fetch_auth(requestString)
      .then((response) => response.json())
      .then((res) => res.map((iam) => InstantAnalysisModule.fromObject(iam)));
  }

  /**
   * Request the names of the default IAMs from the backend.
   * If not type is not provided, return a mapping of annotation type to default iams.
   * Otherwise, return a list of the default IAM names.
   * @param {string} type The type of IAMs to get the names for.
   * @returns {Promise} Promise containing a list of the default IAM names for the annotation type.
   */
  static async getDefaultIamNames(type) {
    return fetch_auth(`/api/analysis/default_iam_names?type=${type}`).then(
      (res) => {
        if (res.ok) {
          return res.json();
        }
        let errorStr = `Failed to get default IAM names [${res.status}]`;
        if (res.statusMessage) {
          errorStr += `: ${res.statusMessage}`;
        }
        throw new Error(errorStr);
      }
    );
  }

  /**
   * Request all Job names from the backend.
   * @returns {Promise} Promise containing the job names.
   */
  static async getJobNames() {
    return fetch_auth(`/api/analysis/jobs`).then((response) => response.json());
  }
  // #endregion

  // #region Audio Controller
  /**
   * Load a specific audio file to frontend.
   * @param {string} id UUID of the file to be loaded.
   * @returns {Promise} Promise containing the audio file.
   */
  static async loadAudioFile(id) {
    return fetch_auth(`/api/audio/load_audio_file?id=${id}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else {
          try {
            return res.json();
          } catch {
            return { error: `Could not load file ${id}` };
          }
        }
      })
      .then((res) => {
        if (res.error)
          throw Error(`Failed to load file ${id}:\n\n${res.error}`);
        return window.URL.createObjectURL(res);
      });
  }
  // #endregion

  // #region UNSAFE: Direct, unauthenticated python server calls
  /**
   * gets metadata from .hsasld file
   */
  static getFileMetadata(path, callback) {
    fetch(PYTHON_SERVER_URL + "/api/v1/scan/get_hsasld_meta?path=" + path)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => callback(myJson));
  }
  // #endregion
}
